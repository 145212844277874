import React from "react"

import Layout2 from "../components/layout2"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout2>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout2>
)

export default NotFoundPage
